
import axios from "./axiosInstance.js";
import {COVID19Test, MedicalTestType, MedicalTest, Page, CystitisDocument, CystitisTest} from "../types/types.ts";

export const COVID19_TEST_URL_IDENTIFIER     = 'covid19';
export const CYSTITIS_TEST_URL_IDENTIFIER    = 'cystitis';
export const MEDICAL_TEST_URL_IDENTIFIER = 'medical_test';

 class MedicalTestService {

   static getMedicalTestNameFromType (testType){
    switch (testType) {
      case MedicalTestType.COVID19:
        return 'Test Antigénique Covid 19';
      case MedicalTestType.CYSTITIS:
        return 'Test Détection Cystite';
      default:
        return null;
    }
  }
  //CYSTITIS
 static  getAllCystitislTestFromPatientId(patientId : string) : Promise<Page<CystitisTest>> {
     return axios.get<Page<CystitisTest>>(`/medical_test`, {
         params: { patientId: patientId, type: MedicalTestType.CYSTITIS }
     }).then(response => response.data);
 }
 static  getAllCystitisTestFromStatus(status : string, pageNumber: number, pageSize:number) : Promise<Page<CystitisTest>> {
     return axios.get<Page<CystitisTest>>( `/medical_test?page=${pageNumber}&size=${pageSize}`, {
         params: { status, type: MedicalTestType.CYSTITIS }
     }).then(response => response ? response.data : null);
 }
 static saveCystitisTest(cystitisTest: CystitisTest): Promise<CystitisTest> {
     return axios.post<CystitisTest>(`/medical_test/${CYSTITIS_TEST_URL_IDENTIFIER}/${cystitisTest.id}`, cystitisTest)
         .then(response => response.data);
 }
 static validateCystitisTest(cystitisTestId: string, status : string): Promise<CystitisTest> {
     return axios.post<CystitisTest>(`/medical_test/${CYSTITIS_TEST_URL_IDENTIFIER}/${cystitisTestId}/validate`, {status})
         .then(response => response.data);
 }
 static startCystitisForPatientId(patientId) : Promise<CystitisTest> {
     return axios.post(`/patient/${patientId}/start_cystitis_test`).then(response => response.data);
 }
 static downloadCystitisTestDocument(cystitisTestId: string, documentType: CystitisDocument): Promise<{ fileBlob: Blob, fileName: string }> {
     return axios({
         url: `/medical_test/cystitis/${cystitisTestId}/download/${documentType}`,
         method: 'GET',
         responseType: 'blob',
     })
         .then(response => {
             debugger;
             const fileName = response.headers['content-disposition']
                 ?.split('filename=')[1]
                 ?.replace(/"/g, '') || 'document.pdf';

             return {
                 fileBlob: new Blob([response.data]),
                 fileName: fileName
             };
         });
 }


  //COVID19
  static  getAllCOVID19MedicalTestFromPatientId(patientId : string) : Promise<Page<COVID19Test>> {
    return axios.get<Page<COVID19Test>>(`/medical_test`, {
      params: { patientId: patientId, type: MedicalTestType.COVID19 }
    }).then(response => response.data);
  }

   static  getAllCOVID19MedicalTestFromStatus(status : string, pageNumber: number, pageSize:number) : Promise<Page<COVID19Test>> {
     return axios.get<Page<COVID19Test>>( `/medical_test?page=${pageNumber}&size=${pageSize}`, {
         params: { status, type: MedicalTestType.COVID19 }
     }).then(response => response ? response.data : null);
   }

  static saveCOVID19MedicalTest(covid19Test: COVID19Test): Promise<COVID19Test> {
    return axios.post<COVID19Test>(`/medical_test/${COVID19_TEST_URL_IDENTIFIER}/${covid19Test.id}`, covid19Test)
        .then(response => response.data);
  }
  static validateCOVID19MedicalTest(covid19TestId: string, status : string): Promise<COVID19Test> {
     return axios.post<COVID19Test>(`/medical_test/${COVID19_TEST_URL_IDENTIFIER}/${covid19TestId}/validate`, {status})
         .then(response => response.data);
  }
  static startCovidTestForPatientId(patientId) : Promise<COVID19Test> {
     return axios.post(`/patient/${patientId}/start_covid_test`).then(response => response.data);
   }

  //Medical Test

 static  getAllMedicalTestFromStatus(status : string, pageNumber: number, pageSize:number) : Promise<Page<MedicalTest>> {
     return axios.get<Page<COVID19Test>>( `/medical_test?page=${pageNumber}&size=${pageSize}`, {
         params: { status }
     }).then(response => response ? response.data : null);
 }
  static getMedicalTestById(medicalTestId : string): Promise<MedicalTest> {
    return axios.get<MedicalTest>(`/medical_test/${medicalTestId}`).then(response => response.data);
  }
  static getAllMedicalTestFromPatientId(patientId : string): Promise<Page<MedicalTest>> {
     return axios.get<Page<MedicalTest>>(`/medical_test`, { params: { patientId } }).then(response => response.data);
  }

 static removeMedicalTestFromTestId(medicalTestId : string): Promise<MedicalTest> {
     return axios.delete<MedicalTest>(`/medical_test/${medicalTestId}`).then(response => response.data);
 }

}
export default MedicalTestService;



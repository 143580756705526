import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, Paper, Typography} from '@mui/material';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import {MedicalTest, MedicalTestType} from '../../types/types.ts'; // Assuming MedicalTest type is defined in types.ts
import PatientService from '../../services/patient.service.js';
import MedicalTestService, {MEDICAL_TEST_URL_IDENTIFIER} from "../../services/MedicalTestService.ts";
import NoMedicalTest from "../../scenes/medicalTest/NoMedicalTest.tsx";
import EditIcon from "@mui/icons-material/Edit";
import utilService from "../../services/util.service.js";
import MedicalTestResulChip from "../field/MedicalTestResulChip.tsx";
import {getActionDisplayMessage} from "../../actions/ReduxAction.ts";
import {Delete} from "@mui/icons-material";
import NewMedicalTest from "../medicalTest/NewMedicalTest.tsx";
import MedicalTestDocumentsDropdown from "../../scenes/medicalTest/MedicalTestDocumentsDropdown.tsx";

interface Props {
    user: { accessToken: string };
    sidebar: boolean
}


const GenericTestList: React.FC<Props> = ({ user,sidebar }) => {
    const { patientId } = useParams<{ patientId: string }>();
    const [medicalTests, setMedicalTests] = useState<MedicalTest[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [requireToLoadData, setRequireToLoadData] = useState(true);
    const [patient, setPatient] = useState(null);

    const dispatch = useDispatch();
    const navigate    = useNavigate();


    const getTestTitle = (medicalTest : MedicalTest) : string =>{
        if (!medicalTest ) return "Test inconnu du ";

        if (medicalTest.type === MedicalTestType.CYSTITIS) return "Dépistage cystite du ";
        if (medicalTest.type === MedicalTestType.COVID19) return "Test Covid du ";
        return "Test inconnu du ";
    }
    const handleNewCovidTest = () : void =>{
        setIsLoading(true);
        MedicalTestService.startCovidTestForPatientId(patient.id)
            .then((newCovidTest) => {
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Nouveau test créé","success"));
                navigate(`/patient/${patientId}/${MEDICAL_TEST_URL_IDENTIFIER}/${newCovidTest.id}`);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de la création du test",
                                                     "error"));
            });
    }
    const handleNewCystitisTest = () : void =>{
        setIsLoading(true);
        MedicalTestService.startCystitisForPatientId(patient.id)
            .then((newCystitisTest) => {
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Nouveau test créé","success"));
                navigate(`/patient/${patientId}/${MEDICAL_TEST_URL_IDENTIFIER}/${newCystitisTest.id}`);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de la création du test",
                    "error"));
            });
    }
    const handleRemoveTest = (medicalTest :MedicalTest) : void =>{
        setIsLoading(true);
        MedicalTestService.removeMedicalTestFromTestId(medicalTest.id)
            .then((newCovidTest) => {
                dispatch(getActionDisplayMessage(" Test correctement supprimé","success"));
                setRequireToLoadData(true);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Une erreur s'est produite lors de la suppression du test",
                    "error"));
            });
    }

    useEffect(() => {
        if(!requireToLoadData) return;
        MedicalTestService.getAllMedicalTestFromPatientId(patientId)
            .then(
                (medicalTestsPage) =>
                {
                    setIsLoading(false);console.log(medicalTestsPage.content)
                    setMedicalTests(medicalTestsPage.content);
                }
            )
            .catch(error => {
                setRequireToLoadData(false);
                console.log(error)
            });

        PatientService.getPatient(patientId)
            .then(
                (data) => {
                    setIsLoading(false);
                    setPatient(data.data);
                }
            )
            .catch(error => {
                console.log(error)
            })
        setRequireToLoadData(false);
        // eslint-disable-next-line
    },[requireToLoadData])

    if (!user.accessToken) {
        return <Navigate replace to="/" />;
    } else if (isLoading) {
        return <CircularProgress />;
    } else {
        return (
            <Box sx={{ p: 2, maxWidth: '900px', width: '100%', margin: 'auto', marginLeft: sidebar ? '250px':'0px' }}>
              <Box sx={{ p: 2}}>
               <Grid container >

                   <Grid item xs={12} sm={4} md={4} lg={4} xl={4} p={1}>
                      <NewMedicalTest  buttonLabel={"Démarrer un Test Covid19"} logoUrl={ process.env.PUBLIC_URL + "/icons/medicalTest/covid-test.png" } handleNewTest={()=> handleNewCovidTest()} />

                   </Grid >
                   <Grid  item xs={12} sm={4} md={4} lg={4} xl={4} p={1}>
                       <NewMedicalTest buttonLabel={"Démarrer un dépistage cystite"} logoUrl={ process.env.PUBLIC_URL + "/icons/medicalTest/urine-test.png" } handleNewTest={()=> handleNewCystitisTest()} />

                   </Grid >
                   <Grid  item xs={12} sm={4} md={4} lg={4} xl={4} p={1} sx={{display : 'none'}}>
                       <NewMedicalTest buttonLabel={"Démarrer un TROD angine"} logoUrl={ process.env.PUBLIC_URL + "/icons/medicalTest/angine-test.png" } handleNewTest={()=> handleNewCystitisTest()} />
                   </Grid >
                </Grid>
              </Box>
                {medicalTests.length > 0 ? (
                    medicalTests.map((medicalTest) => (

                        <Paper key={medicalTest.id} sx={{ p: 2, mb: 2 }}>
                          <Grid container>
                              <Grid item sm={12} md={5}>
                                <Typography variant="h4">{getTestTitle(medicalTest)} {utilService.handleDateFormat(medicalTest.creationDate)}</Typography>
                                  {medicalTest.emailDate &&(
                                  <Typography color="grey" variant='h6'>
                                      Résultat envoyé par mail le {utilService.handleDateFormat(medicalTest.emailDate)}
                                  </Typography>
                                  )}
                              </Grid>
                              <Grid item sm={6} md={3}>
                               <Typography variant="h6">Résultat: <MedicalTestResulChip result={medicalTest.status}/> </Typography>
                              </Grid>
                              <Grid item sm={3} md={2}>
                                  <Button component={Link} to={`/patient/${patientId}/${MEDICAL_TEST_URL_IDENTIFIER}/${medicalTest.id}`}
                                          variant="contained" color="primary" startIcon={<EditIcon />}>
                                      Modifier
                                  </Button>
                              </Grid>
                              <Grid item sm={3} md={2}>
                                  <Button onClick={()=> handleRemoveTest(medicalTest)}
                                          variant="outlined" color="primary" startIcon={<Delete />}>
                                      Supprimer
                                  </Button>
                              </Grid>
                              <Grid item sm={12} md={2}>
                                  <MedicalTestDocumentsDropdown medicalTest={medicalTest} />
                              </Grid>
                            </Grid>
                        </Paper>
                    ))
                ) : (
                    <NoMedicalTest patient={patient}/>
                )}
            </Box>
        );
    }
};

function mapStateToProps(state) {
    const user  = state.authReducer.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(GenericTestList);

// Spring Data Page structure for TypeScript
export interface Page<T> {
    content: T[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    last: boolean;
    first: boolean;
    size: number;
    number: number;
    numberOfElements: number;
    empty: boolean;
}
// Pageable to understand the paging structure
export interface Pageable {
    sort: Sort;
    offset: number;
    pageSize: number;
    pageNumber: number;
    paged: boolean;
    unpaged: boolean;
}

// Sort structure
export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export interface Patient {
    id: string;
    socialSecurityNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    address?: string;
    mobilePhoneNumber?: string;
    homePhoneNumber?: string;
    pharmacyRef: Pharmacy;
    birthday?: string;
    afiliateRegime?: string;
}

export interface Pharmacy {
    id: string;
    name: string;
    address: string;
}

export interface ConsultationGroup {
    id: string;
    type: string;
    patientRef: Patient;
    pharmacyRef: Pharmacy;
    consultations: Consultation[];
    newConsultationTypes: string[];
    lastModifiedDate: string;
    creationDate: string;
    billingDate?: string;
    TACDate?: string;
    shouldTAC: boolean;
    billingCode?: string;
    isBillable: boolean;
    price: number;
    billed: boolean;
}

export interface Consultation {
    id: string;
    consultationGroupId: string;
    patientRef: Patient;
    pharmacyRef: Pharmacy;
    status: string;
    lastModifiedDate: string;
    creationDate: string;
    documentationFiles: DocumentationFile[];
    type: string;
}
export interface File {
    id: string;
    fileName: string;
    fileType: string;
    fileUrl: string;
}

export interface DocumentationFile extends File{

}

// Enum for consultation types
export enum ConsultationGroupType {
    PREGNANCY = "PREGNANCY",
    CANCER_TREATMENT = "ORAL_CANCER_TREATMENT",
}

export enum ConsultationType {
    CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS = "CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS",
    CHEMO_OBSERVANCE = "CHEMO_OBSERVANCE",
    CHEMO_FIRST = "CHEMO_FIRST",
    PREGNANCY = "PREGNANCY",
}

// Enum for status types
export enum ConsultationStatus {
    DRAFT = "DRAFT",
    VALIDATED = "VALIDATED",
    BILLED = "BILLED",
    ARCHIVED = "ARCHIVED",
}

export enum MedicalTestType {
    COVID19 = "COVID19",
    CYSTITIS = "CYSTITIS",
}

export interface MedicalTest {
    id: string;
    patientRef: Patient;
    pharmacyRef: Pharmacy;
    status: MedicalTestStatus;
    lastModifiedDate: string;
    creationDate: string;
    resultReport: File[];
    type: MedicalTestType;
    emailResult : MedicalTestStatus;
    emailAddress : string
    emailDate : string
    billingDate?: string;
    billingCode?: string;
}


export interface COVID19Test extends MedicalTest {
    testingKit : CovidTestingKit
    batchNumber: string,
    expiryDate : string
    testDate : string
}

export interface CystitisTest extends MedicalTest {
    isFemale : boolean,
    withCystitisPrescription: boolean,
    isMoreThan16andLessThan65 : boolean,
    hasFever: boolean,
    withPyelonephritisRisk : boolean,
    pregnancyRisk : boolean,
    gynecologicalRisk: boolean,
    hadCystitisInTheLastYear : boolean,
    hadCystitisInTheLastWeeks : boolean,
    withUrinaryAnomalies : boolean,
    withImmunoRisk : boolean,
    withImplantedCatheter : boolean,
    withRenalFailure : boolean,
    hasAntibioticTreatment : boolean,
    hadFluoroquinolones : boolean,
    cystitisStatus :CystitisStatus,
    testDate : string
    negatifTestWithAnomaly: boolean,
    commentForDoctor : string
    positiveTestWithAllergies : boolean
    antibioticsPrescribed : CystitisTreatment,
    prescriptionComment :string
    emailContent :string
    hasPharmacyEmailInCC :boolean;
}

export enum CystitisTreatment {
    FOSFOMYCINE= "FOSFOMYCINE",
    PIVMECILLINAM = "PIVMECILLINAM",
    OTHER = "OTHER"
}

export enum CystitisDocument {
    REPORT_FOR_DOCTOR= "REPORT_FOR_DOCTOR"
}

export interface CovidTestingKit {
    testName: string;
    manufacturer: string;
}
export enum CystitisStatus {
    SHOULD_CONSULT_DOCTOR= "CONSULT_DOCTOR",
    SHOULD_CONSULT_EMERGENCY = "CONSULT_EMERGENCY",
    CAN_TEST_PATIENT = "CAN_TEST_PATIENT",
    NOT_ALLOWED_TO_TEST= "NOT_ALLOWED_TO_TEST",
    NOT_ENOUGH_INFO="NOT_ENOUGH_INFO"
}
export enum MedicalTestStatus {
    IN_PROGRESS= "IN_PROGRESS",
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
    CANCELED = "CANCELD",
    INCONCLUSIVE = "INCONCLUSIVE"
}

import React, { useState, useEffect } from 'react';
import {
    Paper,
    List,
    Stack,
    ListItem,
    Box,
    Typography
  } from "@mui/material";

function HorizontalSelect(props) {
    const [choices] = useState(props?.choices ?? ['Oui', 'Non']);
    const [values] = useState(props?.values ?? [true, false]);

    const initialIndex = values.indexOf(props.value);
    const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(initialIndex);
    const [borderRadius] = useState(props?.borderRadius ?? '4px' );
  
    useEffect(() => {
      const stringValues = values.map(value => value.toString());
      if(props.debug){
        console.log("values.map(value => value.toString()) " +values.map(value => value.toString()));
        console.log("props.value "+props.value);
        console.log("stringValues.includes(props.value.toString()) "+(stringValues ? stringValues.includes(props.value+""): stringValues))
      }

      const valueIsDefined = !(props.value === null || props.value === undefined)
      if (valueIsDefined && stringValues.includes(props.value.toString())){
        const index = stringValues.indexOf(props.value.toString());
          setSelectedChoiceIndex(index);
      }
      else if(!valueIsDefined){
          setSelectedChoiceIndex(-1);
      }

    }, [props,values]);
  
    const handleChoiceClick = (index) => {
      if(index === selectedChoiceIndex){
        //deselect
         setSelectedChoiceIndex(-1);
        props.changeValue(null);
        return;
      }
      setSelectedChoiceIndex(index);
      const newValue = values[index];
      props.changeValue(newValue);
    };

    return (
      <List component={Stack} direction="row" disablePadding>
        {choices.map((choice, choiceIndex) => (
          <ListItem
            key={choiceIndex}
            disablePadding
            onClick={() => handleChoiceClick(choiceIndex)}
            sx={{ cursor: "pointer" }}

          >
            <Paper
              variant="outlined"
              sx={{
                height: "100%",
                width: "100%",
                minHeight: "40px",
                borderRadius: borderRadius,
                backgroundColor:
                    choiceIndex === selectedChoiceIndex ? "lightblue" : "white",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="text">{choice}</Typography>
              </Box>
            </Paper>
          </ListItem>
        ))}
      </List>
    );
  }
  

export default HorizontalSelect;
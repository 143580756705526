import React, { useState } from 'react';
import {Box, Button, Menu, MenuItem} from '@mui/material';
import {CystitisDocument, CystitisTest, MedicalTest, MedicalTestStatus, MedicalTestType} from '../../types/types.ts';
import { Download } from '@mui/icons-material'; // Import the download icon
import MedicalTestService from '../../services/MedicalTestService.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface MedicalTestDocumentsDropdownProps {
    medicalTest: MedicalTest;
}

const MedicalTestDocumentsDropdown: React.FC<MedicalTestDocumentsDropdownProps> = ({ medicalTest }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadCystitisResult = () => {
        handleClose();
        if (medicalTest.type === MedicalTestType.CYSTITIS) {
            MedicalTestService.downloadCystitisTestDocument(medicalTest.id, CystitisDocument.REPORT_FOR_DOCTOR)
                .then(({ fileBlob, fileName }) => {
                    const url = window.URL.createObjectURL(fileBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Error downloading document:', error);
                });
        }
    };

    // Render the button only if the medical test is of type CYSTITIS
    if (medicalTest.type !== MedicalTestType.CYSTITIS) {
        return null;
    }

    if (medicalTest.type === MedicalTestType.CYSTITIS) {
        let  cystitisTest   : CystitisTest  =  medicalTest
        let  hasAttachments : boolean  =
            (cystitisTest.status === MedicalTestStatus.NEGATIVE && cystitisTest.negatifTestWithAnomaly === true) ||
            (cystitisTest.status === MedicalTestStatus.POSITIVE && cystitisTest.positiveTestWithAllergies === true) ||
            (cystitisTest.status === MedicalTestStatus.POSITIVE && cystitisTest.positiveTestWithAllergies === false && cystitisTest.antibioticsPrescribed !== null);
        if(!hasAttachments)  return null;  // Do not render the component if no attachments
    }

    return (
        <Box>
            <Button
                aria-controls="documents-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
                startIcon={<AttachFileIcon />} >

                Documents
            </Button>

            <Menu
                id="documents-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>

                <MenuItem onClick={handleDownloadCystitisResult}>
                    <Download sx={{ marginRight: 1 }} />
                    Résultat TROD cystite
                </MenuItem>
             </Menu>
        </Box>
);
};

export default MedicalTestDocumentsDropdown;



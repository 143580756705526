import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import ConsultationService, { PREGNANCY_URL_IDENTIFIER, CHEMO_URL_IDENTIFIER } from '../../services/consultation.service.js';
import ChemoFirstConsultationDetail from './chemo/chemoFirstConsultationDetail.component.js';
import PregnancyConsultationDetail from './pregnancy/pregnancyConsultationDetail.component.js';
import ChemoDailyLifeSideEffects from './chemo/chemoDailyLifeSideEffects.component.js';
import ChemoObservance from './chemo/chemoObservance.component.js';

function ConsultationDetail(props) {
  const { consultationId } = useParams();
  const [consultation, setConsultation] = useState(null);
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const urlIdentifyer = props.urlIdentifyer;
  let getConsultationServiceById;

  if (urlIdentifyer === CHEMO_URL_IDENTIFIER) {
    getConsultationServiceById = ConsultationService.getChemoConsultationById;
  } else if (urlIdentifyer === PREGNANCY_URL_IDENTIFIER) {
    getConsultationServiceById = ConsultationService.getPregnancyConsultationById;
  }

  useEffect(() => {
    if (!getConsultationServiceById || !isLoading) return; 

    getConsultationServiceById(consultationId)
      .then(data => {
        setIsLoading(false);
        setConsultation(data.data);
        setPatient(data.data.patientRef);
      })
      .catch(error => {
        console.error(error);
      });
       // eslint-disable-next-line
  }, [consultationId, getConsultationServiceById]);

  const getComponentToDisplay = () => {
    if (!consultation) return null;

    switch (consultation.type) {
      case 'CHEMO_FIRST':
        return <ChemoFirstConsultationDetail consultation={consultation} patient={patient} /* *__/ debug={true} /* /*//>;
      case 'CHEMO_DAILY_LIFE_AND_SIDE_EFFECTS':
        return <ChemoDailyLifeSideEffects consultation={consultation} patient={patient} /* *__/ debug={true} /* /*//>;
      case 'CHEMO_OBSERVANCE':
        return <ChemoObservance consultation={consultation} patient={patient} /* *__/ debug={true} /* /*//>;
      case 'PREGNANCY':
        return <PregnancyConsultationDetail consultation={consultation} patient={patient} /* *__/ debug={true} /* /*//>;
      default:
        console.error("Consultation type not recognised: " + consultation.type);
        return <div>Erreur: Consultation non reconnue</div>;
    }
  };

  if (!getConsultationServiceById) {
    return <div>Erreur: URL non reconnue</div>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return getComponentToDisplay();
}

export default ConsultationDetail;

import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import MedicalTestService, {MEDICAL_TEST_URL_IDENTIFIER} from '../../services/MedicalTestService.ts';
import COVID19TestDetail from './covid19/COVID19TestDetail.tsx';
import CystisisTestDetail from './cystitis/CystisisTestDetail.tsx';
import {CystitisStatus, CystitisTest, MedicalTest, MedicalTestStatus, MedicalTestType} from '../../types/types.ts';
import {Dispatch} from "redux";
import {useDispatch} from "react-redux";
import {getActionDisplayMessage} from "../../actions/ReduxAction.ts";

const fakeCystitisTest: CystitisTest = {
    id: "12345",
    patientRef: {
        id: "54321",
        firstName: "Marie",
        lastName: "Dupont",
        birthday: "1985-06-15",
        socialSecurityNumber: "123-45-6789",
        email: "marie.dupont@example.com"
    },
    pharmacyRef: {
        id: "67890",
        name: "Pharmacy Central",
        address: "123 Main St, Cityville",
        phoneNumber: "123-456-7890"
    },
    status: MedicalTestStatus.IN_PROGRESS,
    lastModifiedDate: new Date().toISOString(),
    creationDate: new Date().toISOString(),
    resultReport: [],
    type: MedicalTestType.CYSTITIS,
    emailResult: null,
    emailAddress: null,
    emailDate: null,
    billingDate: null,
    billingCode: null,
    testingKit: null,
    batchNumber: null,
    expiryDate: null,
    testDate: "2024-06-20",
    isFemale: true,
    withCystitisPrescription: null,
    isMoreThan16: null,
    isMoreThan16andLessThan65: null,
    hasFever: null,
    withPyelonephritisRisk: null,
    pregnancyRisk: null,
    gynecologicalRisk: null,
    hadCystitisInTheLastYear: null,
    hadCystitisInTheLastWeeks: null,
    withUrinaryAnomalies: null,
    withImmunoRisk: null,
    withImplantedCatheter: null,
    withRenalFailure: null,
    hasAntibioticTreatment: null,
    hadFluoroquinolones: null,
    cystitisStatus: CystitisStatus.CAN_TEST_PATIENT
};

const MedicalTestDetail: React.FC = () => {
    const { testId } = useParams<{ testId: string }>();
    const [medicalTest, setMedicalTest] = useState<MedicalTest | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch: Dispatch = useDispatch()
    const navigate = useNavigate();
    const { patientId } = useParams<{ patientId: string }>();

    useEffect(() => {
        if(testId === '1') {
            setMedicalTest(fakeCystitisTest);
            setIsLoading(false);
            return;
        }
        MedicalTestService.getMedicalTestById(testId)
            .then(medicalTest => {
                setIsLoading(false);
                setMedicalTest(medicalTest);
            })
            .catch(error => {
                console.error('Error fetching Medical test details:', error);
                setIsLoading(false);
                dispatch(getActionDisplayMessage("Impossible de récuperer le test ", "error"))
                navigate(`/patient/${patientId}/${MEDICAL_TEST_URL_IDENTIFIER}`);
            });
    }, [testId]);

    const getComponentToDisplay = () => {
        if (!medicalTest) return null;

        switch (medicalTest.type) {
            case MedicalTestType.COVID19:
                return <COVID19TestDetail medicalTest={medicalTest} patient={medicalTest.patientRef} /* *__/ debug={true} /* /*//>;
            case MedicalTestType.CYSTITIS:
                return <CystisisTestDetail medicalTest={medicalTest} patient={medicalTest.patientRef} /* *__/ debug={true} /* /*//>;
             default:
                console.error("Medical type not recognised: " + medicalTest.type);
                return <div>Erreur: Test non reconnue</div>;
        }
    };


    if (isLoading) {
        return <CircularProgress />;
    }

    return getComponentToDisplay();
};

export default MedicalTestDetail;
